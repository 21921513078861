// HomePage.js
import React from "react";
import PropTypes from "prop-types";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import armTheme from "../styles/armTheme";

// Styles
const useStyles = makeStyles({
  container: {
    display: "block",
    width: "100%",
    padding: "8px 0 0 0",
  },
  subheaders: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "0.35em",
  },
  dots: {
    fontSize: "10px",
    margin: "0 14px",
    marginBottom: "0.35em",
  },
  divider: {
    color: armTheme.colours.primary.main,
  },
  model: {
    fontWeight: 500,
  },
  make: {
    color: armTheme.colours.primary.main,
    fontSize: "0.8em",
  },
  type: {
    fontWeight: 400,
  },
  typeBan: {
    color: "#ffae42",
  },
});

export default function ExpansionPanelSummaryContent({ result }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h5" component="h2" gutterBottom className={classes.model}>{result.model.raw} <span className={classes.divider}>|</span> <span className={classes.make}>{result.make.raw}</span></Typography>
      <div className={classes.subheaders}>
        <Typography variant="h6" component="h3" gutterBottom className={classes.type}>{result.type.raw}</Typography>
        <FiberManualRecordIcon className={classes.dots} />
        <Typography variant="h6" component="h3" gutterBottom className={classes.type}>{result.action.raw}</Typography>
        <FiberManualRecordIcon className={classes.dots} />
        <Typography variant="h6" component="h3" gutterBottom className={classes.type}>{result.legal_classification.raw}</Typography>
        {result.banned_oic1.raw == "Yes" ? (
            <>
              <FiberManualRecordIcon className={classes.dots} />
              <Typography variant="h6" component="h3" gutterBottom className={classes.typeBan}>Affected by OIC #1</Typography>
            </>
          ) : null
        }
        {result.banned_oic2.raw == "Yes" ? (
            <>
              <FiberManualRecordIcon className={classes.dots} />
              <Typography variant="h6" component="h3" gutterBottom className={classes.typeBan}>Affected by OIC #2</Typography>
            </>
          ) : null
        }
        {result.banned_oic3.raw == "Yes" ? (
            <>
              <FiberManualRecordIcon className={classes.dots} />
              <Typography variant="h6" component="h3" gutterBottom className={classes.typeBan}>Affected by OIC #3</Typography>
            </>
          ) : null
        }
        {result.banned.raw == "Yes (Live FRT Only)" ? (
            <>
              <FiberManualRecordIcon className={classes.dots} />
              <Typography variant="h6" component="h3" gutterBottom className={classes.typeBan}>Prohibited in Live FRT</Typography>
            </>
          ) : null
        }
      </div>
      <div className={classes.subheaders}>
        {result.calibres.raw ? <Typography variant="body2" component="h4" gutterBottom className={classes.calibres}>Calibres:&nbsp;&nbsp;&nbsp;&nbsp;</Typography> : null}
        {
          (result.calibres.raw)
          ? (
              result.calibres.raw.map((cal, i) => (
                  (i == (result.calibres.raw.length - 1)) ?
                    <Typography variant="body2" component="h4" gutterBottom key={i} className={classes.calibres}>{cal}</Typography>
                  :
                    <Typography variant="body2" component="h4" gutterBottom key={i} className={classes.calibres}>{cal},&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
              ))

          ) : null
        }
      </div>
    </div>
  );
}

ExpansionPanelSummaryContent.propTypes = {
    result: PropTypes.object
};
